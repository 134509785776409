
import {  reactive,  ref,  watch, onMounted } from "vue";
import  {updateServico} from '@/services/Estoque'
import {Modal} from "bootstrap";
import useAlert from "@/composables/Alert";

export default {
    name: 'ModalUpdateUnidadeCaixa',
    props: {
      servico: {        
        required: true
      },
      index:{
        required: true
      }    
    },

    setup(props, {emit}){
      const { showTimeAlert } = useAlert();
      const dadosServico = ref()
      const indexServico = ref()
      const formRef = ref<null | HTMLFormElement>(null);
      const loadingButton = ref(false)  
      const formUnidadeCaixa : any = reactive({
        unidadePorCaixa: 0
      })
      onMounted(() => {
        const auxElement: any = document.getElementById("kt_modallog_3");
        auxModal.value = new Modal(auxElement);        
      })
      
      let  auxModal : any = ref() ;
      const rules = ref({
        unidadePorCaixa : [{
          required: true,
          message: 'Insira um Valor',
          trigger: "change"
        }]
      })      

      watch(() => props.servico, () => {
        dadosServico.value = props.servico
        indexServico.value = props.index
        formUnidadeCaixa.unidadePorCaixa = dadosServico.value?.unidadesCaixa       
      })

      const submit = async () => {
        loadingButton.value = true        
        if (!formRef.value) return;
        formRef.value.validate((valid) => {
          if (valid) {
            if(formUnidadeCaixa.unidadePorCaixa == dadosServico.value?.unidadesCaixa ) {
              loadingButton.value = false
              auxModal.value.hide()              
              return
            }
            updateServico(dadosServico.value.codServico, {unidadescaixa : Number(formUnidadeCaixa.unidadePorCaixa)}).then(() => {
              loadingButton.value = false
              auxModal.value.hide();             
              emit('unidadePorCaixa', {
                unidadePorCaixa:  Number(formUnidadeCaixa.unidadePorCaixa),
                indexServico: indexServico.value
              })
              
              showTimeAlert(`Unidades por caixa Alterado com sucesso`);
            }).catch((error) => {
              showTimeAlert("Não foi possível completar a solicitação", "error");
              console.log(error)
              loadingButton.value = false
            })
          }else {
            loadingButton.value = false

          }
        })
      }
      

      return {
        loadingButton,
        dadosServico,
        formUnidadeCaixa,
        formRef,
        rules,
        submit
      }
    }
}
