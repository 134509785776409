
  import {  reactive,  ref,  watch, onMounted } from "vue";
  import  {updateServico} from '@/services/Estoque'
  import {Modal} from "bootstrap";
  import useAlert from "@/composables/Alert";
  
  export default {
      name: 'ModalUpdateUnidadeCaixa',
      props: {
        servico: {        
          required: true
        },
        index:{
          required: true
        }    
      },
  
      setup(props, {emit}){
        const { showTimeAlert } = useAlert();
        const dadosServico = ref()
        const indexServico = ref()
        const formRef = ref<null | HTMLFormElement>(null);
        const loadingButton = ref(false)  
        const formCodPeca : any = reactive({
          codPeca: 0
        })
        onMounted(() => {
          const auxElement: any = document.getElementById("kt_modallog_2");
          auxModal.value = new Modal(auxElement);        
        })
        
        let  auxModal : any = ref() ;
        const rules = ref({
          codPeca : [{
            required: true,
            message: 'Insira um Valor',
            trigger: "change"
          }]
        })      
  
        watch(() => props.servico, () => {
          dadosServico.value = props.servico
          indexServico.value = props.index
          formCodPeca.codPeca = dadosServico.value?.intServico       
        })
  
        const submit = async () => {
          loadingButton.value = true        
          if (!formRef.value) return;
          formRef.value.validate((valid) => {
            if (valid) {
              if(formCodPeca.codPeca == dadosServico.value?.intServico ) {
                loadingButton.value = false
                auxModal.value.hide()              
                return
              }
              updateServico(dadosServico.value.codServico, {intServico : formCodPeca.codPeca}).then(() => {
                loadingButton.value = false
                auxModal.value.hide();             
                emit('codPeca', {
                  codPeca:  formCodPeca.codPeca,
                  indexServico: indexServico.value
                })
                
                showTimeAlert(`Cod. Peça Alterado com sucesso`);
              }).catch((error) => {
                showTimeAlert("Não foi possível completar a solicitação", "error");
                console.log(error)
                loadingButton.value = false
              })
            }else {
              loadingButton.value = false
  
            }
          })
        }
        
  
        return {
          loadingButton,
          dadosServico,
          formCodPeca,
          formRef,
          rules,
          submit
        }
      }
  }
  